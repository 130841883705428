<template>
  <v-responsive>
    <v-container fluid class="pl-0 pr-0">
      <v-card class="pa-5 pl-0 pr-0" elevation="0">
        <keep-alive>
          <v-row class="ma-5">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-row>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-card class="mt-4 mx-auto" elevation="0" outlined>
                    <v-card
                      class="v-sheet--offset ml-3"
                      color="blue lighten-1"
                      elevation="1"
                      width="80px"
                      height="80px"
                    >
                      <v-icon x-large class="pa-5 white--text">mdi-calendar-today</v-icon>
                    </v-card>
                    <v-card-text class="pt-0">
                      <h2 class="heading font-weight-normal text-right">
                        {{ $t('dashboard.today') }}
                      </h2>
                      <v-progress-linear
                        v-if="Dashboard.state.globalStatsLoading"
                        indeterminate
                        color="grey lighten-5"
                        height="33"
                        class="mt-1 mb-1"
                      >
                        <template v-slot="{ value }"></template>
                      </v-progress-linear>
                      <h1 v-else class="heading font-weight-normal text-right mt-1">
                        {{ Dashboard.state.symbol }}
                        {{
                          Dashboard.state.globalStats.today[`payout${Dashboard.state.currency}`]
                            | floatFormatCommons
                        }}
                      </h1>
                      <v-divider class="my-2"></v-divider>
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.payoutToday')
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-card class="mt-4 mx-auto" elevation="0" outlined>
                    <v-card
                      class="v-sheet--offset ml-3"
                      color="blue lighten-1"
                      elevation="1"
                      width="80px"
                      height="80px"
                    >
                      <v-icon x-large class="pa-5 white--text">mdi-calendar-month</v-icon>
                    </v-card>
                    <v-card-text class="pt-0">
                      <h2 class="heading font-weight-normal text-right">
                        {{ $t('dashboard.month') }}
                      </h2>
                      <v-progress-linear
                        v-if="Dashboard.state.globalStatsLoading"
                        indeterminate
                        color="grey lighten-5"
                        height="33"
                        class="mt-1 mb-1"
                      >
                        <template v-slot="{ value }"></template>
                      </v-progress-linear>
                      <h1 v-else class="heading font-weight-normal text-right mt-1">
                        {{ Dashboard.state.symbol }}
                        {{
                          Dashboard.state.globalStats.thisMonth[`payout${Dashboard.state.currency}`]
                            | floatFormatCommons
                        }}
                      </h1>
                      <v-divider class="my-2"></v-divider>
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.payoutMonth')
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-card class="mt-4 mx-auto" elevation="0" outlined>
                    <v-card
                      class="v-sheet--offset ml-3"
                      color="blue lighten-1"
                      elevation="1"
                      width="80px"
                      height="80px"
                    >
                      <v-icon x-large class="pa-5 white--text">mdi-calendar-month-outline</v-icon>
                    </v-card>
                    <v-card-text class="pt-0">
                      <h2 class="heading font-weight-normal text-right">
                        {{ $t('dashboard.lastMonth') }}
                      </h2>
                      <v-progress-linear
                        v-if="Dashboard.state.globalStatsLoading"
                        indeterminate
                        color="grey lighten-5"
                        height="33"
                        class="mt-1 mb-1"
                      >
                        <template v-slot="{ value }"></template>
                      </v-progress-linear>
                      <h1 v-else class="heading font-weight-normal text-right mt-1">
                        {{ Dashboard.state.symbol }}
                        {{
                          Dashboard.state.globalStats.lastMonth[`payout${Dashboard.state.currency}`]
                            | floatFormatCommons
                        }}
                      </h1>
                      <v-divider class="my-2"></v-divider>
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.payoutLastMonth')
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-card class="mt-4 mx-auto" elevation="0" outlined>
                    <v-card
                      class="v-sheet--offset ml-3"
                      color="blue lighten-1"
                      elevation="1"
                      width="80px"
                      height="80px"
                    >
                      <v-icon x-large class="pa-5 white--text">mdi-math-integral</v-icon>
                    </v-card>
                    <v-card-text class="pt-0">
                      <h2 class="heading font-weight-normal text-right">
                        {{ $t('dashboard.monthForecast') }}
                      </h2>
                      <v-progress-linear
                        v-if="Dashboard.state.globalStatsLoading"
                        indeterminate
                        color="grey lighten-5"
                        height="33"
                        class="mt-1 mb-1"
                      >
                        <template v-slot="{ value }"></template>
                      </v-progress-linear>
                      <h1 v-else class="heading font-weight-normal text-right mt-1">
                        {{ Dashboard.state.symbol }}
                        {{
                          Dashboard.state.globalStats.monthForecast[
                            `payout${Dashboard.state.currency}`
                          ] | floatFormatCommons
                        }}
                      </h1>
                      <v-divider class="my-2"></v-divider>
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.payoutMonthForecast')
                      }}</span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12">
                  <v-card elevation="0" rounded color="transparent">
                    <v-card-title class="heading font-weight-normal">
                      <h4 class="heading font-weight-normal grey--text">
                        {{ $t('dashboard.title.titleChart') }}
                      </h4>
                      <v-spacer />
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.title.titleRealTime')
                      }}</span>
                    </v-card-title>
                    <v-tabs align-with-title v-model="timelineStatsTab">
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab class="text-capitalize">60 Minutes</v-tab>
                      <v-tab class="text-capitalize">24 Hours</v-tab>
                      <v-tab class="text-capitalize">15 Days</v-tab>
                      <v-tab class="text-capitalize">6 Months</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="timelineStatsTab">
                      <v-tab-item>
                        <apexchart
                          class="ma-1 py-3"
                          type="line"
                          height="433"
                          :options="timelineStatsData.state.chart.options"
                          :series="timelineStatsData.state.chart.series"
                        ></apexchart>
                      </v-tab-item>
                      <v-tab-item>
                        <apexchart
                          class="ma-1 py-3"
                          type="line"
                          height="433"
                          :options="timelineStatsData.state.chart.options"
                          :series="timelineStatsData.state.chart.series"
                        ></apexchart>
                      </v-tab-item>
                      <v-tab-item>
                        <apexchart
                          class="ma-1 py-3"
                          type="line"
                          height="433"
                          :options="timelineStatsData.state.chart.options"
                          :series="timelineStatsData.state.chart.series"
                        ></apexchart>
                      </v-tab-item>
                      <v-tab-item>
                        <apexchart
                          class="ma-1 py-3"
                          type="line"
                          height="433"
                          :options="timelineStatsData.state.chart.options"
                          :series="timelineStatsData.state.chart.series"
                        ></apexchart>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12">
                  <v-card elevation="0" tile>
                    <v-card-title class="heading font-weight-normal">
                      <h4 class="heading font-weight-normal grey--text">
                        {{ $t('dashboard.title.titleList') }}
                      </h4>
                      <v-spacer />
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.title.titleMonth')
                      }}</span>
                    </v-card-title>
                    <v-divider class="mx-0"></v-divider>

                    <v-data-table
                      :headers="headersTopOffers"
                      :items="Dashboard.state.topOffersItems"
                      :loading="Dashboard.state.topOffersItemsLoading"
                      hide-default-footer
                    >
                      <template v-slot:item._offer="{ item }">
                        <v-chip
                          router
                          :to="{ name: 'Offers', params: { _offer: item.offer._id } }"
                          label
                          small
                          style="padding: 0"
                        >
                          <FormattedText :value="item.offer._id" :num="15" />
                        </v-chip>
                      </template>
                      <template v-slot:item.offer="{ item }">
                        <FormattedText :value="item.offer.name" :num="55" />
                      </template>
                      <template v-slot:item.transactions="{ item }">
                        <v-chip small label>{{ item.transactions | integerFormat }}</v-chip>
                      </template>
                      <template v-slot:item.conversions="{ item }">
                        <v-chip small label>{{ item.conversions | integerFormat }}</v-chip>
                      </template>
                      <template v-slot:item.payout="{ item }">
                        <v-chip small label
                          >{{ Dashboard.state.symbol }}
                          {{ item[`payout${Dashboard.state.currency}`] | floatFormatCommons }}
                          {{ Dashboard.state.currency }}</v-chip
                        >
                      </template>
                      <template v-slot:item.cr="{ item }">
                        <v-chip small label color="blue lighten-1" textColor="white"
                          >{{ item.cr | floatFormatCommons }} %
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12">
                  <v-card elevation="0" tile>
                    <v-card-title class="heading font-weight-normal">
                      <h4 class="heading font-weight-normal grey--text">Top Locations</h4>
                      <v-spacer />
                      <v-icon class="mr-2" small>mdi-information-variant</v-icon>
                      <span class="caption grey--text font-weight-light">{{
                        $t('dashboard.title.titleMonth')
                      }}</span>
                    </v-card-title>
                    <v-divider class="mx-3"></v-divider>
                    <v-data-table
                      :headers="headersTopLocations"
                      :items="Dashboard.state.topLocationsItems"
                      :loading="Dashboard.state.topLocationsItemsLoading"
                      :hide-default-footer="true"
                    >
                      <template v-slot:item.country="{ item }">
                        {{ item.country ? Countries[item.country] : '-' }}
                      </template>
                      <template v-slot:item.clicks="{ item }">
                        <v-chip small label>{{ item.transactions | integerFormat }}</v-chip>
                      </template>
                      <template v-slot:item.conversions="{ item }">
                        <v-chip small label>{{ item.conversions | integerFormat }}</v-chip>
                      </template>
                      <template v-slot:item.payout="{ item }">
                        <v-chip small label
                          >{{ Dashboard.state.symbol }}
                          {{ item[`payout${Dashboard.state.currency}`] | floatFormatCommons }}
                          {{ Dashboard.state.currency }}</v-chip
                        >
                      </template>
                      <template v-slot:item.cr="{ item }">
                        <v-chip small label color="blue lighten-1" textColor="white"
                          >{{ item.cr | floatFormatCommons }} %
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </keep-alive>
      </v-card>
    </v-container>
  </v-responsive>
</template>
<script>
import { inject, onMounted } from '@vue/composition-api';
import _My from '../../store/private/My';
import CropperComponent from '../Components/CropperComponent';
import FormattedText from '../Components/FormattedText';
import TimelineStats from '../../store/private/TimelineStats';
import Countries from '../../store/constants/Countries';

export default {
  name: 'affiliate-dashboard',
  components: {
    CropperComponent,
    FormattedText,
  },
  setup(props, context) {
    const Application = inject('Application');
    const Dashboard = inject('Dashboard');
    const My = _My(props, context);
    onMounted(async () => {
      await Promise.all([
        My.actions.loadInfo(),
        Dashboard.actions.loadTopOffersItems(),
        Dashboard.actions.loadTopLocationsItems(),
      ]);
    });
    const headersTopOffers = [
      { text: 'Offer Id', value: '_offer', align: 'start', sortable: true, width: '12%' },
      { text: 'Offer', value: 'offer', align: 'start', sortable: true, width: '40%' },
      { text: 'Clicks', value: 'transactions', align: 'center', sortable: true, width: '12%' },
      { text: 'Conversions', value: 'conversions', align: 'center', sortable: true, width: '12%' },
      { text: 'Payout', value: 'payout', align: 'center', sortable: true, width: '12%' },
      { text: 'Conversion Rate', value: 'cr', align: 'center', sortable: true, width: '12%' },
    ];
    const headersTopLocations = [
      { text: 'Country', value: 'country', align: 'start', sortable: true },
      { text: 'Clicks', value: 'clicks', align: 'center', sortable: true, width: 150 },
      { text: 'Conversions', value: 'conversions', align: 'center', sortable: true, width: 150 },
      { text: 'Payout', value: 'payout', align: 'center', sortable: true, width: 150 },
      { text: 'Conversion Rate', value: 'cr', align: 'center', sortable: true, width: 170 },
    ];
    const changeThumbnail = async (url) => {
      Application.state.user.thumbnail = url;
      await My.actions.changeThumbnail(url);
    };
    return {
      Application,
      Dashboard,
      headersTopOffers,
      headersTopLocations,
      Countries,
      My,
      changeThumbnail,
    };
  },
  watch: {
    timelineStatsTab: function (val) {
      this.runTimelineStats(this.timelineStatsConfig[val]);
    },
  },
  methods: {
    runTimelineStats(config) {
      this.timelineStatsData = new TimelineStats(
        config.unit,
        this.Application.state.user.currency,
        ''
      );
      this.timelineStatsData.actions.load();
      if (this.timelineStatsTimer) {
        clearInterval(this.timelineStatsTimer);
      }
      this.timelineStatsTimer = setInterval(() => {
        this.timelineStatsData.actions.load();
      }, config.interval);
    },
  },
  beforeMount() {
    this.runTimelineStats(this.timelineStatsConfig[this.timelineStatsTab]);
  },
  beforeDestroy() {
    clearInterval(this.timelineStatsTimer);
  },
  data() {
    return {
      timelineStatsTab: 0,
      timelineStatsData: null,
      timelineStatsConfig: [
        { unit: 'minute', interval: 10000 },
        { unit: 'hour', interval: 60000 },
        { unit: 'day', interval: 60000 },
        { unit: 'month', interval: 60000 },
      ],
      timelineStatsTimer: null,
    };
  },
};
</script>
<style lang="scss">
.v-avatar--offset {
  top: -38px;
  position: relative;
}
.apexcharts-tooltip {
  background: inherit;
  color: black;
}
</style>
