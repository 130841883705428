import round from 'lodash/round';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import { reactive, computed } from '@vue/composition-api';
import { DateTime } from 'luxon';
import HttpClientV2 from '../HttpClientV2';
import numeral from 'numeral';

const transactionColor = '#FF0000';
const conversionColor = '#FFCC00';
const payoutColor = '#0088EE';

export default function TimelineStats(unit, currency, title = '') {
  const state = reactive({
    items: [],
    loading: false,
    currency: currency,
    chart: computed(() => {
      return {
        series: [
          {
            name: 'Clicks',
            data: state.items.map((currentValue) => currentValue['transactions']),
            color: transactionColor,
          },
          {
            name: 'Conversions',
            data: state.items.map((currentValue) => currentValue['conversions']),
            color: conversionColor,
          },
          {
            name: `Payout ${state.currency}`,
            data: state.items.map((currentValue) =>
              round(currentValue[`payout${state.currency}`], 2)
            ),
            color: payoutColor,
          },
        ],
        options: {
          title: {
            text: title,
            align: 'left',
            style: {
              fontSize: '14px',
              fontWeight: 'light',
            },
          },
          chart: {
            brush: {
              enabled: false,
              target: undefined,
              autoScaleYaxis: false,
            },
            height: 433,
            type: 'line',
            zoom: {
              enabled: true,
            },
            dropShadow: {
              enabled: false,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: [3, 3, 3],
            dashArray: [0, 0, 0],
          },
          legend: {
            labels: {
              colors: [],
            },
            tooltipHoverFormatter: function (val, opts) {
              if (val == 'Clicks' || val == 'Conversions') {
                return (
                  val +
                  ' - ' +
                  numeral(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]).format(
                    '0,0'
                  ) +
                  ''
                );
              } else {
                return (
                  val +
                  ' - ' +
                  numeral(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]).format(
                    '0,0.00'
                  ) +
                  ''
                );
              }
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            categories: state.items.map((currentValue) => currentValue['createdAt']),
            labels: {
              style: {
                colors: '#42A5F5',
              },
              formatter: function (val) {
                switch (unit) {
                  case 'minute':
                    return actions.getHourAndMinute(val);
                  case 'hour':
                    return actions.getHourAndMinute(val);
                  case 'day':
                    return actions.getDayMonthYear(val);
                  case 'month':
                    return actions.getMonthYear(val);
                }
              },
            },
          },
          yaxis: [
            {
              seriesName: 'Clicks',
              opposite: false,
              tickAmount: 10,
              axisTicks: {
                show: true,
                color: transactionColor,
              },
              axisBorder: {
                show: true,
                color: transactionColor,
              },
              labels: {
                show: window.innerWidth >= 1264,
                style: {
                  colors: transactionColor,
                },
                formatter: function (val) {
                  return numeral(val).format('0,0');
                },
              },
              min: 0,
            },
            {
              seriesName: 'Conversions',
              opposite: false,
              axisTicks: {
                show: true,
                color: conversionColor,
              },
              axisBorder: {
                show: true,
                color: conversionColor,
              },
              labels: {
                show: window.innerWidth >= 1264,
                style: {
                  colors: conversionColor,
                },
                formatter: function (val) {
                  return numeral(val).format('0,0');
                },
              },
              min: 0,
            },
            {
              seriesName: `Payout ${state.currency}`,
              opposite: true,
              axisTicks: {
                show: true,
                color: payoutColor,
              },
              axisBorder: {
                show: true,
                color: payoutColor,
              },
              labels: {
                show: window.innerWidth >= 1264,
                style: {
                  colors: payoutColor,
                },
                formatter: function (val) {
                  return numeral(val).format('0,0.00');
                },
              },
              min: 0,
            },
          ],
          tooltip: {
            theme: 'light',
          },
          grid: {
            show: false,
            borderColor: '#f1f1f1',
          },
        },
      };
    }),
  });

  const actions = {
    async load() {
      try {
        set(state, 'loading', true);
        const result = await HttpClientV2.callFunctionV2('GET', 'statistics/TimelineStats', {
          groupBy: unit,
        });
        set(state, 'items', sortBy(result, 'createdAt'));
      } catch (error) {
        set(state, 'items', []);
      } finally {
        set(state, 'loading', false);
      }
    },
    getMonthYear(val) {
      if (val !== undefined && typeof val === 'string') {
        val = val.replace(' ', 'T').replace('.000Z', '') + '.000Z';
        const monthYear = val.substr(0, 7).split('-');
        return monthYear[1] + '/' + monthYear[0];
      }
    },
    getDayMonthYear(val) {
      if (val !== undefined && typeof val === 'string') {
        val = val.replace(' ', 'T').replace('.000Z', '') + '.000Z';
        const dayMonthYear = val.substr(0, 10).split('-');
        return dayMonthYear[1] + '/' + dayMonthYear[2] + '/' + dayMonthYear[0];
      }
    },
    getHourAndMinute(val) {
      if (val !== undefined && typeof val === 'string') {
        val = val.replace(' ', 'T').replace('.000Z', '') + '.000Z';
        return DateTime.fromISO(val)
          .setZone(localStorage.getItem('timeZone') || DateTime.local().zoneName)
          .toLocaleString(DateTime.TIME_SIMPLE);
      }
    },
  };
  return { state, actions };
}
